<template>
  <el-card class="box-card">
    <el-row>
      <el-descriptions class="margin-top" title="" :column="3">
        <el-descriptions-item label="发起人信息">{{ bargain_list.user_obj.name }}</el-descriptions-item>
        <el-descriptions-item label="商品信息">{{ sku_info.name }}</el-descriptions-item>
        <el-descriptions-item label="发起砍价时间">{{
            bargain_list.bargain_goods_obj.bargain_obj.create_at | formatDate
          }}
        </el-descriptions-item>
        <el-descriptions-item label="最少砍价数/已砍人数">{{ bargain_list.bargain_goods_obj.bargain_obj.chop_number }} /
          {{ bargain_list.bargain_goods_obj.succeed_number }}
        </el-descriptions-item>
        <el-descriptions-item label="底价">{{ bargain_list.price }}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{
            bargain_list.bargain_goods_obj.bargain_obj.end_time | formatDate
          }}
        </el-descriptions-item>
        <el-descriptions-item label="砍价状态">
          <span v-if="bargain_list.state == 0">失败</span>
          <span v-else-if="bargain_list.state == 1">进行中</span>
          <span v-else-if="bargain_list.state == 2">成功</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="user_obj.name"
          label="帮砍价人信息">
      </el-table-column>
      <el-table-column
          align="center"
          prop="price"
          label="帮砍金额">
      </el-table-column>
      <el-table-column
          align="center"
          label="帮砍时间"
          prop="create_at"
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetHelpList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {},
  props: [],
  data() {
    return {
      selectRows: '',
      listLoading: false,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      row: {
        user_id: '111',
        name: '111',
        launch_at: '1647584613',
        nimi_bargain: '2341',
        cut_number: '23',
        floor_price: '234',
        end_at: '1647584613',
        state: '1',
      },
      list: [],
      bargain_list: [],
      sku_info: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetHelpList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.bargain_list = res.data.data.bargain
          this.sku_info = res.data.data.sku_info
          this.list = res.data.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
