<template>
  <el-dialog
      :close-on-click-modal="false"
      title="活动详情"
      :visible.sync="dialogFormVisible"
      width="40%"
      @close="close"
  >
    <el-form ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="name">
        <el-tooltip placement="top">
          <div slot="content">活动名称将显示在活动列表中，发布商家管理</div>
          <el-input v-model="form.name" placeholder="请输入活动名称" size="small" style="width: 70%" :disabled="true"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间" prop="begin_time">
        <el-date-picker
            v-model="query_time"
            size="small"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
            :disabled="true"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否参与分销" prop="is_join_distribution">
        <el-radio-group v-model="form.is_join_distribution" disabled size="small">
          <el-radio-button label="0">参与</el-radio-button>
          <el-radio-button label="1">不参与</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="购买方式" prop="distribution">
        <el-radio-group v-model="form.distribution" disabled size="small">
          <el-radio-button label="0">任意金额可购买</el-radio-button>
          <el-radio-button label="1">砍到指定金额才可购买</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="帮砍金额" prop="chop_money">
        <el-radio-group v-model="form.chop_money" disabled size="small">
          <el-radio-button label="0">随机金额</el-radio-button>
          <el-radio-button label="1">固定金额</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.chop_money == 1" style="margin-bottom: 20px" label="固定砍价金额" prop="fixed_money">
        <el-input type="number" v-model="form.fixed_money" :disabled="true" placeholder="请输入金额" style="width: 70%"
                  size="small">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否允许自己砍价" prop="is_oneself_chop">
        <el-switch
            v-model="form.is_oneself_chop"
            active-color="#13ce66"
            active-text="允许"
            active-value="1"
            disabled
            inactive-color="#ff4949"
            inactive-text="不允许"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="砍价人数" prop="chop_number">
        <el-tooltip placement="top">
          <div slot="content">每个用户同一件商品只可砍一刀<br/>帮砍人数为该砍价订单最少人数</div>
          <el-input-number
              v-model="form.chop_number"
              :step="1"
              step-strictly
              :disabled="true"
              :min="1"
              controls-position="right"
              size="small"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="砍价有效时间" prop="chop_validity">
        <el-tooltip placement="top">
          <div slot="content">自用户发起砍价到砍价截止时间</div>
          <el-input-number
              v-model="form.chop_validity"
              :step="1"
              step-strictly
              :disabled="true"
              :min="1"
              controls-position="right"
              size="small"
              style="float: left"
          />
        </el-tooltip>
        <div style="float: left; margin: -4px 0 0 10px">小时</div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table :data="s_list" size="small" style="width: 100%">
          <!--          <el-table-column align="center" show-overflow-tooltip type="selection"/>-->
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="首刀金额"
              prop="first_knife"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.first_knife" :disabled="true"
                        v-model="s_list[scope.$index].first_knife"
                        placeholder="请输入内容" :value="scope.row.first_knife"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].first_knife" :disabled="true"
                        placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="砍价库存"
              prop="inventory"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.inventory" :disabled="true"
                        v-model="s_list[scope.$index].inventory"
                        placeholder="请输入内容" :value="scope.row.inventory"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].inventory" :disabled="true"
                        placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="底价"
              prop="low_price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.low_price" :disabled="true"
                        v-model="s_list[scope.$index].low_price"
                        placeholder="请输入内容" :value="scope.row.low_price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].low_price" :disabled="true"
                        placeholder="请输入内容" :value="0"/>
            </template>
          </el-table-column>
          <!--          <el-table-column align="center" label="操作" width="100">
                      <template #default="{ row }">
                        <el-button type="text" @click="handleDelete(row)">删除</el-button>
                      </template>
                    </el-table-column>-->
        </el-table>
        <!--        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
                  选择商品
                </el-button>
                <el-button v-if="s_list.length > 0" icon="el-icon-delete" type="danger" style="margin-top: 10px"
                           @click="handleDelete">批量删除
                </el-button>
                <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
                </el-button>
                <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>-->
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动规则说明" prop="validity">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.validity"
            :disabled="true"
            style="width: 70%"
        />
      </el-form-item>
      <!--      <el-form-item>
              <el-button type="primary" @click="submit" size="small">保存</el-button>
            </el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">关 闭</el-button>
      <!--      <el-button type="primary" @click="save" size="small">确 定</el-button>-->
    </div>
  </el-dialog>
</template>

<script>
import {apiGetBargainingInfo} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  name: 'Edit',
  data() {
    return {
      query_time: [],
      s_list: [],
      form: {},
      rules: {},
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        const tempObj = Object.assign({}, row)
        this.bargain_id = tempObj.bargain_id
        this.getBargainInfo()
      }
      this.dialogFormVisible = true
    },
    getBargainInfo() {
      apiGetBargainingInfo({id: this.bargain_id}).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.form = {
            id: res.data.id,
            name: res.data.name,
            begin_time: this.formatDate(res.data.begin_time),
            end_time: this.formatDate(res.data.end_time),
            is_join_distribution: res.data.is_join_distribution,
            distribution: res.data.distribution,
            chop_money: res.data.chop_money,
            is_oneself_chop: res.data.is_oneself_chop,
            chop_number: res.data.chop_number,
            fixed_money: res.data.fixed_money,
            chop_validity: res.data.chop_validity,
            validity: res.data.validity,
            goods: this.getGoods(res.data.goods),
          }
          this.query_time = [this.formatDate(res.data.begin_time), this.formatDate(res.data.end_time)]
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd')
    },
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        arr.forEach(item => {
          /*this.list.push({
            sku_id: item.sku_id,
            first_knife: item.first_knife,//首刀金额
            inventory: item.stock,//参与砍价库存
            low_price: item.price,//低价
          })*/
          this.s_list.push({
            sku_id: item.sku_info.skuid,
            imgUrl: item.sku_info.imgUrl,//商品图片
            name: item.sku_info.name,//商品名
            price: item.sku_info.price,//价格
            stock: item.sku_info.stock,//库存
            first_knife: item.first_knife,//首刀金额
            inventory: item.inventory,//参与砍价库存
            low_price: item.low_price,//低价
          })
        })
        return this.list
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const {code, message} = await doEdit(this.form)
          if (code == '200') {
            this.$message.success(message)
            this.$emit('fetch-data')
            this.close()
          } else {
            this.$message.error(message)
            this.$emit('fetch-data')
            this.close()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
